var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"advice-card-wrapper",class:{ 'not-selected': !_vm.show, selected: _vm.show },on:{"click":function($event){return _vm.$emit('selected')}}},[_c('div',{staticClass:"advice-card"},[_vm._m(0),(_vm.appointments && _vm.sortedAppointments)?_c('div',{staticStyle:{"margin-bottom":"80px"}},[_c('div',{staticClass:"mb-30",staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((Object.keys(_vm.sortedAppointments)),function(key,index){return _c('div',{key:index},[_c('h3',{staticClass:"appointment-title"},[_vm._v(_vm._s(key))]),_vm._l((_vm.sortedAppointments[key]),function(appointment,indexApp){return _c('div',{key:indexApp,staticClass:"appointment",class:{
              selected: appointment.id == _vm.selectedAppointment
            },on:{"click":function($event){return _vm.$emit('setAppointment', appointment.id)}}},[_c('div',[_vm._v(_vm._s(_vm.formatTime(appointment.date_time)))]),_c('div',{staticStyle:{"text-align":"right"}},[(
                  appointment.id === _vm.selectedAppointment &&
                  _vm.originalBookedAppointment !== appointment.id
                )?_c('span',[_vm._v("Reserve")]):_vm._e(),(appointment.id === _vm.originalBookedAppointment)?_c('span',[_vm._v("Booked")]):_vm._e()])])})],2)}),0),_c('div',{staticClass:"button-wrapper"}),_c('div',{staticClass:"button-wrapper mt30"},[_c('a',{staticClass:"collapseCoupon",attrs:{"href":"#"},on:{"click":function($event){return _vm.$emit('cancelAppointment')}}},[_vm._v("Continue Without Appointment")])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advice-title"},[_c('h1',{staticClass:"mb30"},[_vm._v("Telephone Appointment")]),_c('div',{staticClass:"question-section-intro mb30"},[_vm._v(" As you have requested advice the next step is to book an appointment for us to contact you. Please select from the avaliable times below: ")])])
}]

export { render, staticRenderFns }