<template>
  <BaseModalIntro
    v-show="show"
    :introTitle="'Important'"
    @close="close"
    :show="show"
    :doNotShowTick="true"
    :global="true"
  >
    <template v-slot:contents>
      Based on your answers to our questions we would recommend you take advice
      on a few points (advice is provided without charge). Please confirm the
      areas you want to receive follow-up advice in.
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'PopUpModal',
  mixins: [TextFormat],
  components: {
    BaseModalIntro
  },
  props: ['show'],
  data() {
    return {
      data: {}
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
