var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"advice-card-wrapper",class:{ 'not-selected': !_vm.show, selected: _vm.show },on:{"click":function($event){return _vm.$emit('selected')}}},[_c('div',{staticClass:"advice-card display-flex"},[_c('div',{staticClass:"advice-title display-flex"},[(_vm.title)?_c('h1',{domProps:{"innerHTML":_vm._s(_vm.title)}}):_c('h1',[_vm._t("title")],2),_c('div',{staticClass:"advice-img",class:{
          requested: _vm.requested,
          declined: _vm.requested === false
        }})]),_c('div',{staticClass:"advice-step-wrapper display-flex"},[(_vm.goal)?_c('div',{staticClass:"advice-step goal"},[_vm._m(0),_c('div',{staticClass:"advice-content",domProps:{"innerHTML":_vm._s(_vm.goal)}},[_vm._t("goal")],2)]):_vm._e(),_c('div',{staticClass:"advice-step problem"},[_vm._m(1),_c('div',{staticClass:"advice-content",domProps:{"innerHTML":_vm._s(_vm.problem)}},[_vm._t("problem")],2)]),_c('div',{staticClass:"advice-step solution"},[_vm._m(2),_c('div',{staticClass:"advice-content",domProps:{"innerHTML":_vm._s(_vm.solution)}},[_vm._t("solution")],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advice-heading"},[_c('div',{staticClass:"advice-heading-point"}),_vm._v(" The Goal ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advice-heading"},[_c('div',{staticClass:"advice-heading-point"}),_vm._v(" The Problem ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advice-heading"},[_c('div',{staticClass:"advice-heading-point"}),_vm._v(" The Solution ")])
}]

export { render, staticRenderFns }