<template>
  <div>
    <BaseModalLargeSplit
      @close="backgroundClose"
      @confirmYes="confirmYes"
      @confirmNo="confirmNo"
      @confirm="save"
      :splitConfirmButtons="show.splitConfirmButtons"
      :buttonText="buttonText"
      :buttonTextYes="show.appointment ? 'Book Appointment' : 'Request Advice'"
      :buttonTextNo="show.appointment ? 'Book ASAP' : 'No'"
      :show="shown"
      :id="'js-adviceModal'"
      :showRight="!show.appointment"
    >
      <template v-slot:menu>
        <div class="tick-wrapper">
          <span></span>
        </div>
        <div class="tick-text">
          ADVICE
          <br />
        </div>
        <div class="advice-body-menu display-flex">
          <div class="advice-intro">
            <h4 class="advice-intro-name">{{ name }}</h4>
            Based on your personal circumstances we have identified a few areas
            where we would recommend you take some advice.
            <br />
            <br />We work closely with an expert team of legal and financial
            advisors to provide information and advice free of charge.
          </div>
          <div class="advice-box-wrapper">
            <div class="advice-box">
              <h5>Expert Legal &amp; Financial Advice</h5>
              <div class="advice-box-content">
                <div class="advice-box-text">
                  <span v-if="coupon && coupon.broker"
                    >Financial advice is provided by the team at
                    {{ coupon.broker.company_name }}, initial advice is free of
                    charge. Legal advice is provided by our sister company
                    Dunham McCarthy Ltd.</span
                  >
                  <span v-else
                    >Financial and legal advice is provided by our sister
                    company Dunham McCarthy Ltd. Initial advice is free of
                    charge.</span
                  >
                </div>
                <div class="advice-box-logos display-flex">
                  <div
                    v-if="coupon && coupon.broker && coupon.broker.logo"
                    class="advice-box-logo-container"
                  >
                    <img alt="Broker logo" :src="coupon.broker.logo" />
                  </div>
                  <div class="advice-box-logo-container">
                    <img
                      alt="Dunham McCarthy logo"
                      src="https://mylastwill.s3.amazonaws.com/static/img/dm-logo.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div v-if="show.overview" class="advice-area-number">ADVICE CENTRE</div>
        <div v-else class="advice-area-number">
          <div v-if="show.appointment">Appointment</div>
          <div v-else>
            Recommendation
            <span id="number">{{ adviceNumber }}</span>
          </div>
        </div>
      </template>
      <template v-slot:left>
        <PowerOfAttorney
          v-if="power"
          v-bind:show="show.power"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.power_of_attorney"
          @selected="showAdvice(() => (show.power = true))"
        ></PowerOfAttorney>
        <HomeProtection
          v-if="protectHome"
          v-bind:show="show.homeProtection"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.protect_home"
          @selected="showAdvice(() => (show.homeProtection = true))"
        ></HomeProtection>
        <SuccessionPlanning
          v-if="business"
          v-bind:show="show.business"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.business"
          @selected="showAdvice(() => (show.business = true))"
        ></SuccessionPlanning>
        <InsuranceReview
          v-if="insuranceReview"
          v-bind:show="show.insuranceReview"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.life_insurance_review"
          @selected="showAdvice(() => (show.insuranceReview = true))"
        ></InsuranceReview>
        <InsuranceShortfall1
          v-if="insuranceShortfall1"
          v-bind:show="show.insuranceShortfall1"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.life_insurance"
          @selected="showAdvice(() => (show.insuranceShortfall1 = true))"
        ></InsuranceShortfall1>
        <InsuranceShortfall2
          v-if="insuranceShortfall2"
          v-bind:show="show.insuranceShortfall2"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.life_insurance"
          @selected="showAdvice(() => (show.insuranceShortfall2 = true))"
        ></InsuranceShortfall2>
        <InternationalInterests
          v-if="abroad"
          v-bind:show="show.abroad"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.assets_abroad"
          @selected="showAdvice(() => (show.abroad = true))"
        ></InternationalInterests>
        <FuneralPlan
          v-if="funeralPlan"
          v-bind:show="show.funeralPlan"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.funeral_plan"
          @selected="showAdvice(() => (show.funeralPlan = true))"
        ></FuneralPlan>
        <InheritanceTax
          v-if="inheritanceTax[0]"
          v-bind:show="show.inheritanceTax"
          v-bind:totalEstate="inheritanceTax[1]"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.inheritance_tax"
          @selected="showAdvice(() => (show.inheritanceTax = true))"
        ></InheritanceTax>
        <Disabled
          v-if="disabled"
          v-bind:show="show.disabled"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.disabled"
          @selected="showAdvice(() => (show.disabled = true))"
        ></Disabled>
        <Children
          v-if="childrenMissing"
          v-bind:show="show.children"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.children"
          @selected="showAdvice(() => (show.children = true))"
        ></Children>
        <Scotland1
          v-if="scotland1"
          v-bind:show="show.scotland1"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.scotland1"
          @selected="showAdvice(() => (show.scotland1 = true))"
        ></Scotland1>
        <Scotland2
          v-if="scotland2"
          v-bind:show="show.scotland2"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.scotland2"
          @selected="showAdvice(() => (show.scotland2 = true))"
        ></Scotland2>
        <Scotland3
          v-if="scotland3"
          v-bind:show="show.scotland3"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.scotland3"
          @selected="showAdvice(() => (show.scotland3 = true))"
        ></Scotland3>
        <RightToOccupy1
          v-if="right_to_occupy1"
          v-bind:show="show.right_to_occupy1"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.right_to_occupy1"
          @selected="showAdvice(() => (show.right_to_occupy1 = true))"
        ></RightToOccupy1>
        <RightToOccupy2
          v-if="right_to_occupy2"
          v-bind:show="show.right_to_occupy2"
          v-bind:total="totalNumberOfAdvice[0]"
          v-bind:requested="data.right_to_occupy2"
          @selected="showAdvice(() => (show.right_to_occupy2 = true))"
        ></RightToOccupy2>
      </template>
      <template v-slot:rightAlternative>
        <Appointment
          v-if="appointment"
          :show="show.appointment"
          :selectedAppointment="data.appointment"
          @setAppointment="data.appointment = $event"
          @cancelAppointment="cancelAppointment"
          @showOverview="showOverview"
          v-bind:total="totalNumberOfAdvice[0]"
        ></Appointment>
      </template>
    </BaseModalLargeSplit>

    <PopUpModal
      :show="show.popUpModal"
      @close="show.popUpModal = false"
    ></PopUpModal>
  </div>
</template>

<script>
import { http } from '@/services'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

import BaseModalLargeSplit from '@/common/ui/BaseModalLargeSplit'
import PowerOfAttorney from './ModalContent/PowerOfAttorney'
import HomeProtection from './ModalContent/HomeProtection'
import FuneralPlan from './ModalContent/FuneralPlan'
import InternationalInterests from './ModalContent/InternationalInterests'
import SuccessionPlanning from './ModalContent/SuccessionPlanning'
import InsuranceReview from './ModalContent/InsuranceReview'
import InsuranceShortfall1 from './ModalContent/InsuranceShortfall1'
import InsuranceShortfall2 from './ModalContent/InsuranceShortfall2'
import InheritanceTax from './ModalContent/InheritanceTax'
import Disabled from './ModalContent/Disabled'
import Children from './ModalContent/Children'
import Scotland1 from './ModalContent/Scotland1'
import Scotland2 from './ModalContent/Scotland2'
import Scotland3 from './ModalContent/Scotland3'
import PopUpModal from './ModalContent/PopUpModal'
import Appointment from './ModalContent/Appointment'
import RightToOccupy1 from '@/views/pages/CheckoutView/AdvicePage/ModalContent/RightToOccupy1'
import RightToOccupy2 from '@/views/pages/CheckoutView/AdvicePage/ModalContent/RightToOccupy2'

export default {
  name: 'AdviceModal',
  mixins: [willStringHelpers],
  props: ['checkout', 'shown'],
  mounted() {
    this.$root.$on('saveAdviceRecommended', () => {
      this.saveAdviceRecommended()
    })
  },
  components: {
    RightToOccupy2,
    RightToOccupy1,
    BaseModalLargeSplit,
    PowerOfAttorney,
    HomeProtection,
    FuneralPlan,
    InternationalInterests,
    InsuranceShortfall1,
    InsuranceShortfall2,
    SuccessionPlanning,
    InsuranceReview,
    InheritanceTax,
    Disabled,
    Children,
    Scotland1,
    Scotland2,
    Scotland3,
    Appointment,
    PopUpModal
  },
  computed: {
    advice() {
      return this.$store.getters.adviceNew
    },
    assetsLiabilities() {
      return this.$store.getters.assets
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    showBooking() {
      return true
    },
    details() {
      return this.$store.getters.details
    },
    coupon() {
      return this.$store.getters.checkoutBasket.coupon
    },
    people() {
      return this.$store.getters.people
    },
    groups() {
      return this.$store.getters.groups
    },
    name() {
      return this.firstWord(this.personalDetails.full_name) + ','
    },
    spouse() {
      if (
        this.people.filter(
          (person) =>
            (person.partner || person.partner_ex) &&
            (person.relationship === 'wife' ||
              person.relationship === 'husband' ||
              person.relationship === 'ex partner')
        ).length > 0
      ) {
        return this.people.filter((person) => person.partner)[0]
      }
      return false
    },
    partner() {
      return this.$store.getters.partner
    },
    livesWithPartner() {
      return !!(this.partner && this.partner.residential_property_occupant)
    },
    leavingAllResidueToPartner() {
      return !!(
        this.partner &&
        (this.partner.distribution_sole_primary ||
          this.partner.distribution_primary_percentage === '100')
      )
    },
    houseInSoleName() {
      return !!(
        this.assetsLiabilities.residential_property &&
        this.assetsLiabilities.residential_property_owner === 'myself'
      )
    },
    hasChildren() {
      return this.people.filter((person) => person.child).length > 0
    },
    children() {
      return this.people.filter((person) => person.child)
    },
    childrenInDistribution() {
      return (
        this.people.filter(
          (person) =>
            person.child &&
            (person.distribution_primary || person.distribution_backup)
        ).length > 0 || this.groups.filter(
            (group) => group.group_name === 'children'
          ).length > 0
      )
    },
    funeral() {
      return this.$store.getters.funeral
    },
    power() {
      if (this.totalAdvice === 0) return true
      else if (
        this.personalDetails &&
        this.personalDetails.dob_year < new Date().getFullYear() - 40
      )
        return true
      return false
    },
    protectHome() {
      return !!(
        this.assetsLiabilities.residential_property &&
        (this.assetsLiabilities.residential_property_joint ||
          this.assetsLiabilities.residential_property_owner !== 'myself') &&
        this.partner &&
        this.partner.distribution_sole_primary &&
        this.childrenInDistribution
      )
    },
    business() {
      return !!(
        this.assetsLiabilities.business &&
        this.assetsLiabilities.business_type === 'limited company' &&
        (this.assetsLiabilities.shareholders === '51%-99%' ||
          this.assetsLiabilities.shareholders === '1%-50%')
      )
    },
    insuranceReview() {
      if (
        this.assetsLiabilities.life_insurance &&
        this.assetsLiabilities.trust !== 'yes'
      ) {
        return false
      }
      return false
    },
    insuranceShortfall1() {
      // have a partner, everything going to partner and mortgage greater than life insurance or no life insurance
      if (
        this.partner &&
        this.partner.distribution_sole_primary &&
        this.assetsLiabilities.life_insurance &&
        this.assetsLiabilities.life_insurance_amount &&
        this.assetsLiabilities.residential_mortgage &&
        this.assetsLiabilities.residential_mortgage_amount &&
        this.assetsLiabilities.residential_mortgage_amount >
          this.assetsLiabilities.life_insurance_amount
      ) {
        return true
      } else
        return !!(
          !this.assetsLiabilities.life_insurance &&
          this.assetsLiabilities.residential_mortgage
        )
    },
    insuranceShortfall2() {
      if (
        !this.assetsLiabilities.life_insurance &&
        this.assetsLiabilities.residential_mortgage &&
        !this.partner
      ) {
        return false
      }
      return false
    },
    abroad() {
      if (this.assetsLiabilities.assets_outside_uk) {
        return false
      }
      return false
    },
    funeralPlan() {
      // disable funeral plan advice as service is now regulated
      return false
      // return (
      //   this.funeral &&
      //   !this.funeral.funeral_plan &&
      //   this.personalDetails.dob_year < new Date().getFullYear() - 50
      // )
    },
    inheritanceTax() {
      var assets = 0
      var liabilities = 0
      if (this.assetsLiabilities.residential_property) {
        if (this.assetsLiabilities.residential_property_amount) {
          this.assetsLiabilities.residential_property_joint
            ? (assets += this.assetsLiabilities.residential_property_amount / 2)
            : (assets += this.assetsLiabilities.residential_property_amount)
        }
      }
      if (this.assetsLiabilities.blt_property) {
        if (this.assetsLiabilities.blt_property_amount) {
          this.assetsLiabilities.blt_property_joint
            ? (assets += this.assetsLiabilities.blt_property_amount / 2)
            : (assets += this.assetsLiabilities.blt_property_amount)
        }
      }
      if (this.assetsLiabilities.savings_bank) {
        if (this.assetsLiabilities.savings_bank_amount) {
          this.assetsLiabilities.savings_bank_joint
            ? (assets += this.assetsLiabilities.savings_bank_amount / 2)
            : (assets += this.assetsLiabilities.savings_bank_amount)
        }
      }
      if (this.assetsLiabilities.life_insurance) {
        if (this.assetsLiabilities.life_insurance_amount) {
          this.assetsLiabilities.life_insurance_joint
            ? (assets += this.assetsLiabilities.life_insurance_amount / 2)
            : (assets += this.assetsLiabilities.life_insurance_amount)
        }
      }
      if (this.assetsLiabilities.vehicle) {
        if (this.assetsLiabilities.vehicle_amount) {
          assets += this.assetsLiabilities.vehicle_amount
        }
      }
      if (this.assetsLiabilities.pension) {
        if (this.assetsLiabilities.pension_amount) {
          assets += this.assetsLiabilities.pension_amount
        }
      }
      if (this.assetsLiabilities.shares_investments) {
        if (this.assetsLiabilities.shares_investments_amount) {
          this.assetsLiabilities.shares_investments_joint
            ? (assets += this.assetsLiabilities.shares_investments_amount / 2)
            : (assets += this.assetsLiabilities.shares_investments_amount)
        }
      }
      if (this.assetsLiabilities.endowment) {
        if (this.assetsLiabilities.endowment_amount) {
          this.assetsLiabilities.endowment_joint
            ? (assets += this.assetsLiabilities.endowment_amount / 2)
            : (assets += this.assetsLiabilities.endowment_amount)
        }
      }
      if (this.assetsLiabilities.business) {
        if (this.assetsLiabilities.business_amount) {
          assets += this.assetsLiabilities.business_amount
        }
      }
      if (this.assetsLiabilities.household) {
        if (this.assetsLiabilities.household_amount) {
          assets += this.assetsLiabilities.household_amount
        }
      }
      if (this.assetsLiabilities.gold_jewellery) {
        if (this.assetsLiabilities.gold_jewellery_amount) {
          assets += this.assetsLiabilities.gold_jewellery_amount
        }
      }
      if (this.assetsLiabilities.residential_mortgage) {
        if (this.assetsLiabilities.residential_mortgage_amount) {
          this.assetsLiabilities.residential_mortgage_joint
            ? (liabilities +=
                this.assetsLiabilities.residential_mortgage_amount / 2)
            : (liabilities += this.assetsLiabilities
                .residential_mortgage_amount)
        }
      }
      if (this.assetsLiabilities.blt_mortgage) {
        if (this.assetsLiabilities.blt_mortgage_amount) {
          this.assetsLiabilities.blt_mortgage_joint
            ? (liabilities += this.assetsLiabilities.blt_mortgage_amount / 2)
            : (liabilities += this.assetsLiabilities.blt_mortgage_amount)
        }
      }
      if (this.assetsLiabilities.credit_cards) {
        if (this.assetsLiabilities.credit_cards_amount) {
          liabilities += this.assetsLiabilities.credit_cards_amount
        }
      }
      if (this.assetsLiabilities.loans) {
        if (this.assetsLiabilities.loans_amount) {
          liabilities += this.assetsLiabilities.loans_amount
        }
      }
      const totalEstate = assets - liabilities

      if (
        this.personalDetails.marital_status === 'Married' &&
        totalEstate > 650000
      ) {
        return [true, totalEstate]
      } else if (totalEstate > 325000) {
        return [true, totalEstate]
      }
      return [false, totalEstate]
    },
    disabled() {
      return !!this.details.distribution_disabled
    },
    excludedChildren() {
      return this.$store.getters.people.filter(
        (person) => person.child && !person.distribution_primary
      )
    },
    excludedFutureChildren() {
      let expecting = this.personalDetails.children === 'expecting'
      let group = this.$store.getters.groups.filter(
        (group) => group.group_name === 'Future Children'
      )
      return expecting && !group.length
    },
    childrenMissing() {
      if (
        this.children.length !==
        this.children.filter(
          (child) => child.distribution_primary || child.distribution_backup
        ).length
      ) {
        // check if children are named all together in a group
        if (
          this.$store.getters.groups.filter(
            (group) => group.group_name === 'children'
          ).length > 0
        )
          return false
        if (this.excludedFutureChildren) return true
        // check if children are name severally in a group

        const sonsGroup = this.$store.getters.groups.filter((group) =>
          group.group_name.includes('son')
        ).length

        const daughtersGroup = this.$store.getters.groups.filter((group) =>
          group.group_name.includes('daughter')
        ).length

        const sonsPeople = this.children.filter(
          (child) => child.gender === 'Male'
        ).length

        const daughtersPeople = this.children.filter(
          (child) => child.gender === 'Female'
        ).length

        let sonsMissing = []
        let daughtersMissing = []
        if (!sonsGroup && sonsPeople) {
          sonsMissing = this.excludedChildren.filter(
            (child) => child.gender === 'Male'
          )
        }
        if (!daughtersGroup && daughtersPeople) {
          daughtersMissing = this.excludedChildren.filter(
            (child) => child.gender === 'Female'
          )
        }
        return !!sonsMissing.concat(daughtersMissing).length
      }
      return !!this.excludedFutureChildren
    },

    scotland1() {
      return !!(
        this.personalDetails.country === 'Scotland' &&
        this.spouse &&
        this.hasChildren
      )
    },
    scotland2() {
      return !!(
        this.personalDetails.country === 'Scotland' &&
        this.spouse &&
        !this.hasChildren
      )
    },
    scotland3() {
      return !!(
        this.personalDetails.country === 'Scotland' &&
        !this.spouse &&
        this.hasChildren
      )
    },
    right_to_occupy1() {
      return !!(
        this.houseInSoleName &&
        this.hasChildren &&
        this.livesWithPartner &&
        this.leavingAllResidueToPartner
      )
    },
    right_to_occupy2() {
      return !!(
        this.houseInSoleName &&
        this.livesWithPartner &&
        !this.leavingAllResidueToPartner
      )
    },
    appointmentBrokerRequired() {
      // if broker insurance only service then skip appointment booking
      // else if broker and other service booking appointment required
      if (this.coupon && this.coupon.broker) {
        if (
          (this.data.life_insurance || this.data.life_insurance_review) &&
          (this.data.power_of_attorney ||
            this.data.protect_home ||
            this.data.business ||
            this.data.inheritance_tax ||
            this.data.assets_abroad ||
            this.data.funeral_plan ||
            this.data.disabled ||
            this.data.children ||
            this.data.scotland1 ||
            this.data.scotland2 ||
            this.data.scotland3 ||
            this.data.right_to_occupy1 ||
            this.data.right_to_occupy2)
        )
          return true
      }
      return false
    },
    appointment() {
      // if broker do not request booking of appointment
      if (this.appointmentBrokerRequired) return true
      // broker and my last will provided services
      if (this.data.life_insurance && (!this.coupon || !this.coupon.broker))
        return true
      if (
        this.data.life_insurance_review &&
        (!this.coupon || !this.coupon.broker)
      )
        return true
      // my last will provided services
      if (this.data.power_of_attorney) return true
      if (this.data.protect_home) return true
      if (this.data.business) return true
      if (this.data.inheritance_tax) return true
      if (this.data.assets_abroad) return true
      if (this.data.funeral_plan) return true
      if (this.data.disabled) return true
      if (this.data.children) return true
      if (this.data.scotland1) return true
      if (this.data.scotland2) return true
      if (this.data.scotland3) return true
      if (this.data.right_to_occupy1) return true
      if (this.data.right_to_occupy2) return true
      return false
    },
    totalNumberOfAdvice() {
      // for CSS
      var total = 0
      if (this.power) total += 1
      if (this.protectHome) total += 1
      if (this.business) total += 1
      if (this.insuranceReview) total += 1
      if (this.insuranceShortfall1 || this.insuranceShortfall2) total += 1
      if (this.abroad) total += 1
      if (this.funeralPlan) total += 1
      if (this.inheritanceTax[0]) total += 1
      if (this.disabled) total += 1
      if (this.childrenMissing) total += 1
      if (this.scotland1) total += 1
      if (this.scotland2) total += 1
      if (this.scotland3) total += 1
      if (this.right_to_occupy1) total += 1
      if (this.right_to_occupy2) total += 1
      if (this.appointment) total += 1
      if (total > 5) return [total, total]
      return [5, total]
    },
    totalAdvice() {
      // exclude power of attorney
      var total = 0
      if (this.protectHome) total += 1
      if (this.business) total += 1
      if (this.insuranceReview) total += 1
      if (this.insuranceShortfall1 || this.insuranceShortfall2) total += 1
      if (this.abroad) total += 1
      if (this.funeralPlan) total += 1
      if (this.inheritanceTax[0]) total += 1
      if (this.disabled) total += 1
      if (this.childrenMissing) total += 1
      if (this.scotland1) total += 1
      if (this.scotland2) total += 1
      if (this.scotland3) total += 1
      if (this.right_to_occupy1) total += 1
      if (this.right_to_occupy2) total += 1
      return total
    },
    allQuestionsAnswered() {
      var done = true
      if (this.power && this.data.power_of_attorney === null) done = false
      if (this.protectHome && this.data.protect_home === null) done = false
      if (this.business && this.data.business === null) done = false
      if (this.insuranceReview && this.data.life_insurance_review === null) {
        done = false
      }
      if (this.insuranceShortfall1 && this.data.life_insurance === null) {
        done = false
      }
      if (this.insuranceShortfall2 && this.data.life_insurance === null) {
        done = false
      }
      if (this.abroad && this.data.assets_abroad === null) done = false
      if (this.funeralPlan && this.data.funeral_plan === null) done = false
      if (this.inheritanceTax[0] && this.data.inheritance_tax === null) {
        done = false
      }
      if (this.disabled && this.data.disabled === null) done = false
      if (this.childrenMissing && this.data.children === null) done = false
      if (this.scotland1 && this.data.scotland1 === null) done = false
      if (this.scotland2 && this.data.scotland2 === null) done = false
      if (this.scotland3 && this.data.scotland3 === null) done = false
      if (this.right_to_occupy1 && this.data.right_to_occupy1 === null)
        done = false
      if (this.right_to_occupy2 && this.data.right_to_occupy2 === null)
        done = false
      //  if (this.appointment && this.data.appointment === null) done = false
      return done
    },
    buttonText() {
      if (
        this.appointment &&
        this.show.appointment &&
        this.allQuestionsAnswered
      ) {
        return 'Save'
      } else if (!this.appointment && this.allQuestionsAnswered) return 'Save'
      return 'Next'
    }
  },
  watch: {
    advice: {
      deep: true,
      immediate: true,
      handler(value) {
        this.data.id = value.id
        this.data.power_of_attorney = value.power_of_attorney
        this.data.protect_home = value.protect_home
        this.data.business = value.business
        this.data.life_insurance = value.life_insurance
        this.data.life_insurance_review = value.life_insurance_review
        this.data.inheritance_tax = value.inheritance_tax
        this.data.assets_abroad = value.assets_abroad
        this.data.funeral_plan = value.funeral_plan
        this.data.disabled = value.disabled
        this.data.children = value.children
        this.data.scotland1 = value.scotland1
        this.data.scotland2 = value.scotland2
        this.data.scotland3 = value.scotland3
        this.data.right_to_occupy1 = value.right_to_occupy1
        this.data.right_to_occupy2 = value.right_to_occupy2
        this.data.appointment = value.appointment
      }
    },
    shown: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value && this.checkout) {
          this.show.popUpModal = true
        }
      }
    }
  },
  data() {
    return {
      show: {
        overview: true,
        power: false,
        homeProtection: false,
        business: false,
        insuranceReview: false,
        insuranceShortfall1: false,
        insuranceShortfall2: false,
        abroad: false,
        funeralPlan: false,
        inheritanceTax: false,
        disabled: false,
        children: false,
        scotland1: false,
        scotland2: false,
        scotland3: false,
        right_to_occupy1: false,
        right_to_occupy2: false,
        splitConfirmButtons: false,
        appointment: false,
        popUpModal: false
      },
      data: {
        id: null,
        power_of_attorney: null,
        protect_home: null,
        business: null,
        life_insurance_review: null,
        life_insurance: null,
        funeral_plan: null,
        assets_abroad: null,
        inheritance_tax: null,
        disabled: null,
        children: null,
        scotland1: null,
        scotland2: null,
        scotland3: null,
        right_to_occupy1: null,
        right_to_occupy2: null,
        appointment: null
      },
      adviceNumber: 0,
      progress: false,
      saveLoading: false
    }
  },
  methods: {
    showAdvice(value) {
      this.showNothing()
      value()
      this.show.splitConfirmButtons = true
      this.label()
    },
    showOverview() {
      this.show.splitConfirmButtons = false
      Object.keys(this.show).forEach((key) => {
        if (key === 'overview') {
          this.show[key] = true
        } else {
          this.show[key] = false
        }
      })
    },
    showNothing() {
      Object.keys(this.show).forEach((key) => {
        this.show[key] = false
      })
    },
    showAppointmentBooking() {
      this.showNothing()
      this.show.appointment = true
    },
    clear() {
      this.showNothing()
      this.show.splitConfirmButtons = true
      this.label()
    },
    save(override = false) {
      if (!this.allQuestionsAnswered) {
        this.cycleShowQuestions()
      } else if (
        this.allQuestionsAnswered &&
        this.appointment &&
        !this.show.appointment &&
        override === false
      ) {
        this.showAppointmentBooking()
      } else {
        this.resetPrevious()

        if (this.data.id && !this.saveLoading) {
          this.saveLoading = true

          http
            .patch('/wills/api/advice_new', this.data)
            .then((response) => {
              this.$store.commit('adviceNew', response.data)
              if (this.checkout) {
                this.$router.push('/share').catch((error) => {
                  console.log(error)
                })
              }
              this.close()
              this.$notification.success(
                'Advice request received, you shall be contacted shortly'
              )
              this.saveLoading = false
              this.saveAdviceRecommended()
            })
            .catch((error) => {
              if (error.response.data.appointment) {
                this.$notification.error(error.response.data.appointment)
              }
              this.saveLoading = false
            })
        }
      }
    },
    resetPrevious() {
      // reset questions to null if a question has not been presented to user
      if (!this.power && this.data.power_of_attorney) {
        this.data.power_of_attorney = null
      }
      if (!this.protectHome && this.data.protect_home) {
        this.data.protect_home = null
      }
      if (!this.business && this.data.business) this.data.business = null
      if (!this.insuranceReview && this.data.life_insurance_review) {
        this.data.life_insurance_review = null
      }
      if (!this.insuranceShortfall1 && this.data.life_insurance) {
        this.data.life_insurance = null
      }
      if (!this.abroad && this.data.abroad) this.data.abroad = null
      if (!this.funeralPlan && this.data.funeral_plan) {
        this.data.funeral_plan = null
      }
      if (!this.inheritanceTax[0] && this.data.inheritance_tax) {
        this.data.inheritance_tax = null
      }
      if (!this.disabled && this.data.disabled) this.data.disabled = null
      if (!this.childrenMissing && this.data.children) {
        this.data.children = null
      }
      if (!this.scotland1 && this.data.scotland1) this.data.scotland1 = null
      if (!this.scotland2 && this.data.scotland2) this.data.scotland2 = null
      if (!this.scotland3 && this.data.scotland3) this.data.scotland3 = null
      if (!this.right_to_occupy1 && this.data.right_to_occupy1) {
        this.data.right_to_occupy1 = null
      }
      if (!this.right_to_occupy2 && this.data.right_to_occupy2) {
        this.data.right_to_occupy2 = null
      }
    },
    cycleShowQuestions() {
      const keys = Object.keys(this.data)
      for (var i = 0; i < keys.length; i++) {
        if (
          keys[i] === 'power_of_attorney' &&
          this.data[keys[i]] === null &&
          this.power
        ) {
          this.clear()
          this.show.power = true
          break
        } else if (
          keys[i] === 'protect_home' &&
          this.data[keys[i]] === null &&
          this.protectHome
        ) {
          this.clear()
          this.show.homeProtection = true
          break
        } else if (
          keys[i] === 'business' &&
          this.data[keys[i]] === null &&
          this.business
        ) {
          this.clear()
          this.show.business = true
          break
        } else if (
          keys[i] === 'life_insurance_review' &&
          this.data[keys[i]] === null &&
          this.insuranceReview
        ) {
          this.clear()
          this.show.insuranceReview = true
          break
        } else if (
          keys[i] === 'life_insurance' &&
          this.data[keys[i]] === null &&
          (this.insuranceShortfall1 || this.insuranceShortfall2)
        ) {
          this.clear()
          if (this.insuranceShortfall1) {
            this.show.insuranceShortfall1 = true
          } else if (this.insuranceShortfall2) {
            this.show.insuranceShortfall2 = true
          }
          break
        } else if (
          keys[i] === 'funeral_plan' &&
          this.data[keys[i]] === null &&
          this.funeralPlan
        ) {
          this.clear()
          this.show.funeralPlan = true
          break
        } else if (
          keys[i] === 'assets_abroad' &&
          this.data[keys[i]] === null &&
          this.abroad
        ) {
          this.clear()
          this.show.abroad = true
          break
        } else if (
          keys[i] === 'inheritance_tax' &&
          this.data[keys[i]] === null &&
          this.inheritanceTax[0]
        ) {
          this.clear()
          this.show.inheritanceTax = true
          break
        } else if (
          keys[i] === 'disabled' &&
          this.data[keys[i]] === null &&
          this.disabled
        ) {
          this.clear()
          this.show.disabled = true
          break
        } else if (
          keys[i] === 'children' &&
          this.data[keys[i]] === null &&
          this.childrenMissing
        ) {
          this.clear()
          this.show.children = true
          break
        } else if (
          keys[i] === 'scotland1' &&
          this.data[keys[i]] === null &&
          this.scotland1
        ) {
          this.clear()
          this.show.scotland1 = true
        } else if (
          keys[i] === 'scotland2' &&
          this.data[keys[i]] === null &&
          this.scotland2
        ) {
          this.clear()
          this.show.scotland2 = true
        } else if (
          keys[i] === 'scotland3' &&
          this.data[keys[i]] === null &&
          this.scotland3
        ) {
          this.clear()
          this.show.scotland3 = true
        } else if (
          keys[i] === 'right_to_occupy1' &&
          this.data[keys[i]] === null &&
          this.right_to_occupy1
        ) {
          this.clear()
          this.show.right_to_occupy1 = true
        } else if (
          keys[i] === 'right_to_occupy2' &&
          this.data[keys[i]] === null &&
          this.right_to_occupy2
        ) {
          this.clear()
          this.show.right_to_occupy2 = true
        }
      }
    },
    confirmYes() {
      if (this.show.power) {
        this.data.power_of_attorney = true
      } else if (this.show.homeProtection) {
        this.data.protect_home = true
      } else if (this.show.insuranceReview) {
        this.data.life_insurance_review = true
      } else if (
        this.show.insuranceShortfall1 ||
        this.show.insuranceShortfall2
      ) {
        this.data.life_insurance = true
      } else if (this.show.business) {
        this.data.business = true
      } else if (this.show.abroad) {
        this.data.assets_abroad = true
      } else if (this.show.funeralPlan) {
        this.data.funeral_plan = true
      } else if (this.show.inheritanceTax) {
        this.data.inheritance_tax = true
      } else if (this.show.disabled) {
        this.data.disabled = true
      } else if (this.show.children) {
        this.data.children = true
      } else if (this.show.scotland1) {
        this.data.scotland1 = true
      } else if (this.show.scotland2) {
        this.data.scotland2 = true
      } else if (this.show.scotland3) {
        this.data.scotland3 = true
      } else if (this.show.right_to_occupy1) {
        this.data.right_to_occupy1 = true
      } else if (this.show.right_to_occupy2) {
        this.data.right_to_occupy2 = true
      }
      this.showOverview()
    },
    confirmNo() {
      if (this.show.power) {
        this.data.power_of_attorney = false
      } else if (this.show.homeProtection) {
        this.data.protect_home = false
      } else if (this.show.insuranceReview) {
        this.data.life_insurance_review = false
      } else if (
        this.show.insuranceShortfall1 ||
        this.show.insuranceShortfall2
      ) {
        this.data.life_insurance = false
      } else if (this.show.business) {
        this.data.business = false
      } else if (this.show.abroad) {
        this.data.assets_abroad = false
      } else if (this.show.funeralPlan) {
        this.data.funeral_plan = false
      } else if (this.show.inheritanceTax) {
        this.data.inheritance_tax = false
      } else if (this.show.disabled) {
        this.data.disabled = false
      } else if (this.show.children) {
        this.data.children = false
      } else if (this.show.scotland1) {
        this.data.scotland1 = false
      } else if (this.show.scotland2) {
        this.data.scotland2 = false
      } else if (this.show.scotland3) {
        this.data.scotland3 = false
      } else if (this.show.right_to_occupy1) {
        this.data.right_to_occupy1 = false
      } else if (this.show.right_to_occupy2) {
        this.data.right_to_occupy2 = false
      }

      this.showOverview()
    },
    displayValue(value) {
      if (value === null) {
        return 'No value'
      } else if (value) {
        return 'accepted advice'
      } else {
        return 'declined advice'
      }
    },
    label() {
      var elements = document.getElementsByClassName('advice-card-wrapper')
      var self = this
      setTimeout(function () {
        for (var i = 0; i < elements.length; i++) {
          if (elements[i].className === 'advice-card-wrapper selected') {
            self.adviceNumber = i + 1
          }
        }
      }, 30)
    },
    close() {
      this.showOverview()
      this.$emit('close')
    },
    backgroundClose() {
      if (!this.checkout) this.close()
    },
    cancelAppointment() {
      this.data.appointment = null
      this.save(true)
    },
    saveAdviceRecommended() {
      let form = {
        protect_home_recommended: this.protectHome,
        power_of_attorney_recommended: this.power,
        life_insurance_recommended:
          this.insuranceShortfall1 || this.insuranceShortfall2,
        life_insurance_review_recommended: this.insuranceReview,
        inheritance_tax_recommended: this.inheritanceTax[0],
        funeral_plan_recommended: this.funeralPlan,
        assets_abroad_recommended: this.abroad,
        business_recommended: this.business,
        disabled_recommended: this.disabled,
        scotland1_recommended: this.scotland1,
        scotland2_recommended: this.scotland2,
        scotland3_recommended: this.scotland3,
        children_recommended: this.childrenMissing,
        right_to_occupy1_recommended: this.right_to_occupy1,
        right_to_occupy2_recommended: this.right_to_occupy2
      }

      http.patch('/wills/api/advice_recommended', form).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
