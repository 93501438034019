<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
    @selected="$emit('selected')"
  >
    <template v-slot:title
      >Would you like to discuss
      <span>'Lasting Power of Attorney'</span>?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'PowerOfAttorney',
  mixins: [willStringHelpers],
  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    people() {
      return this.$store.getters.people
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    partner() {
      return this.people.filter((p) => p.partner)[0]
    },
    assetsLiabilities() {
      return this.$store.getters.assets
    },
    businessText() {
      if (this.assetsLiabilities.business)
        return 'This is especially important as you have business interests.'
      return ''
    },
    adultChildren() {
      var now = new Date()
      var max18 = new Date(
        now.getFullYear() - 18,
        now.getMonth(),
        now.getDate()
      )
      var adultChildren = this.people.filter(
        (p) =>
          p.child &&
          max18 > new Date(p.dob_year, Number(p.dob_month - 1), p.dob_day)
      )
      if (adultChildren.length > 0) return [true, adultChildren]
      return [false, adultChildren]
    },
    goal() {
      if (
        this.partner &&
        (this.personalDetails.marital_status !== 'Single' ||
          this.personalDetails.marital_status !== 'Seperated')
      ) {
        // everyone with a partner
        return (
          'To prevent ' +
          this.span(this.partner.full_name) +
          ' and other family members having to go through the court if you have an accident or illness in the future.'
        )
      } else if (
        (this.personalDetails.marital_status === 'Single' ||
          this.personalDetails.marital_status === 'Separated') &&
        this.adultChildren[0]
      ) {
        // everyone without a partner and adult children
        return (
          'To prevent ' +
          this.listPeople(this.adultChildren[1]) +
          ' or other family members having to go through the court if you have an accident or illness in the future.'
        )
      } else {
        // everyone without a partner regardless of children
        return "To prevent family members having to go through the court to obtain 'Deputyship' if you have an accident or illness in the future."
      }
    },
    problem() {
      if (
        this.partner &&
        (this.personalDetails.marital_status !== 'Single' ||
          this.personalDetails.marital_status !== 'Seperated')
      ) {
        // everyone with a partner
        return (
          'If you have an accident or suffer from a serious illness (a stroke for example), ' +
          this.firstWord(this.partner.full_name) +
          ' or other close family members ' +
          'would have to deal with your affairs (your bank accounts, utilities ' +
          this.listAssetsLiabilities(this.assetsLiabilities) +
          ' and other general affairs). ' +
          " This is done by applying for 'Deputyship' through the courts of protection. Obtaining deputyship is a stressful, lengthy and expensive process" +
          ' that can take several months and cost thousands of pounds. Whilst the deputyship application is pending your accounts including any joint accounts, would be frozen.  ' +
          this.businessText
        )
      } else if (
        (this.personalDetails.marital_status === 'Single' ||
          this.personalDetails.marital_status === 'Separated') &&
        this.adultChildren[0]
      ) {
        // everyone without a partner and adult children
        return (
          'If you have an accident or suffer from a serious illness (a stroke for example), ' +
          this.listPeople(this.adultChildren[1], ' and ', true) +
          ' or other close family members would have to deal with your ' +
          'affairs (your bank accounts, utilities' +
          this.listAssetsLiabilities(this.assetsLiabilities) +
          ' and other general affairs). This is done by ' +
          "applying for 'Deputyship' through the courts of protection. Obtaining deputyship is a stressful, lengthy and expensive process that can take several months " +
          'and cost thousands of pounds. Whilst the deputyship application is pending your accounts including any joint accounts, would be frozen.  ' +
          this.businessText
        )
      } else {
        // everyone without a partner regardless of children
        return (
          'If you have an accident or suffer from a serious illness (a stroke for example), your family would have to deal with your affairs (your bank accounts, ' +
          'utilities' +
          this.listAssetsLiabilities(this.assetsLiabilities) +
          " and other general affairs). This is done by applying for 'Deputyship' " +
          'through the courts of protection. Obtaining deputyship is a stressful, lengthy and expensive process that can take several months and cost thousands of ' +
          'pounds. Whilst the deputyship application is pending your accounts including any joint accounts, would be frozen.  ' +
          this.businessText
        )
      }
    },
    solution() {
      if (
        this.partner &&
        (this.personalDetails.marital_status !== 'Single' ||
          this.personalDetails.marital_status !== 'Seperated')
      ) {
        // everyone with a partner
        return (
          'By making your Power of Attorney if you have an accident or illness it will be easy for ' +
          this.firstWord(this.partner.full_name) +
          ' or other nominated people ' +
          'to deal with matters. The courts would not need to be involved, Deputyship would not be needed. Lasting Powers of Attorney are seperate ' +
          'to your Will and cost between £349 and £599 to put in place.'
        )
      } else if (
        (this.personalDetails.marital_status === 'Single' ||
          this.personalDetails.marital_status === 'Separated') &&
        this.adultChildren[0]
      ) {
        // everyone without a partner and adult children
        return (
          'By making your Power of Attorney if you have an accident or illness it will be easy for ' +
          this.listPeople(this.adultChildren[1], ' and ', true) +
          ' or ' +
          'other nominated people to deal with matters. The courts would not need to be involved, Deputyship would not be needed. Lasting Powers of ' +
          'Attorney are separate to your Will and cost between £349 and £599 to put in place.'
        )
      } else {
        // everyone without a partner regardless of children
        return (
          'By making your Power of Attorney if you have an accident or illness it will be easy for your ' +
          'nominated family members to deal with matters. The courts would not need to be involved, Deputyship would not be needed. Lasting Powers of ' +
          'Attorney are separate to your Will and cost between £349 and £599 to put in place.'
        )
      }
    }
  }
}
</script>
