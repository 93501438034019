<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :requested="requested"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
    @selected="$emit('selected')"
  >
    <template v-slot:title
      >Would you like to discuss <span>'Your Business'</span>?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'SuccessionPlanning',
  mixins: [willStringHelpers],

  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    people() {
      return this.$store.getters.people
    },
    partner() {
      return this.$store.getters.partner
    },
    otherBeneficiaris() {
      return this.people.filter(
        (person) => person.distribution_primary && !person.partner
      )
    },
    otherBeneficiarisText() {
      if (this.otherBeneficiaris.length)
        return this.listPeople(this.otherBeneficiaris, ' and', true)
      return this.hisHer(this.partner.gender) + ' substitute beneficiaries'
    },
    goal() {
      if (
        this.partner &&
        (this.partner.distribution_sole_primary ||
          this.partner.distribution_primary_percentage === '100')
      ) {
        return 'To ensure your business interests pass to the correct people whilst protecting the existing shareholders.'
      } else {
        return 'To ensure your business interests pass to the correct people whilst protecting the existing shareholders.'
      }
    },
    problem() {
      if (
        this.partner &&
        (this.partner.distribution_sole_primary ||
          this.partner.distribution_primary_percentage === '100')
      ) {
        return (
          'When you pass away your business interests (your company shares) will pass to ' +
          this.span(this.partner.full_name) +
          ' or if ' +
          this.firstWord(this.partner.full_name) +
          ' has passed away with or before you they will pass to ' +
          this.otherBeneficiarisText +
          '. The remaining shareholders will be in business ' +
          'with your beneficiaries; this could be problematic for a various reasons. Would your beneficiaries would be capable of running the business? Do they have the necessary ' +
          'skill-set? Would they want the responsibility? How would the existing shareholders feel? '
        )
      } else {
        return (
          'When you pass away your business interests (your company shares) will pass to ' +
          this.listPeople(this.otherBeneficiaris) +
          '. The remaining shareholders will be in business with your ' +
          'beneficiaries; this could be problematic for a various reasons. Would your beneficiaries would be capable of running the business? Do they have the necessary skill-set? ' +
          ' Would they want the responsibility? How would the existing shareholders feel? '
        )
      }
    },
    solution() {
      if (
        this.partner &&
        (this.partner.distribution_sole_primary ||
          this.partner.distribution_primary_percentage === '100')
      ) {
        return "You should speak with an specialist about 'Business Succession Planning', they can help you and the other shareholders document what should happen if a shareholder passes away or loses their mental capacity (this is often called a cross-option agreement). The remaining shareholders could remain in control and instead of shares, your beneficiaries would receive a cash lump sum (often provided by a special type of insurance policy)."
      } else {
        return "You should speak with an specialist about 'Business Succession Planning', they can help you and the other shareholders document what should happen if a shareholder passes away or loses their mental capacity (this is often called a cross-option agreement). The remaining shareholders could remain in control and instead of shares, your beneficiaries would receive a cash lump sum (often provided by a special type of insurance policy)."
      }
    }
  }
}
</script>

<style></style>
