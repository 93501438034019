<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    @selected="$emit('selected')"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
  >
    <template v-slot:title
      >Would you like to discuss <span>'Insurance Review'</span>?</template
    >
    <template v-slot:goal
      >To have the family home passed down for the future benefit of Kerry
      Clansey and Steve Clansey.</template
    >
    <template v-slot:problem
      >You have life insurance but it is not in a trust</template
    >
    <template v-slot:solution
      >A trust could be created to protect the family home for
      <span>Kerry</span> and <span>Steve</span>. There are different types off
      trust available, most are effective against future care fees, divorce and
      bankruptcy. A typical trust would cost £349 to setup.</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'

export default {
  name: 'InsuranceReview',
  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    }
  }
}
</script>

<style></style>
