<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
    @selected="$emit('selected')"
  >
    <template v-slot:title
      >Would you like to discuss
      <span>'Life Insurance Shortfall'</span>?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'InsuranceShortfall1',
  mixins: [willStringHelpers],
  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    coupon() {
      return this.$store.getters.checkoutBasket.coupon
    },
    broker() {
      if (this.coupon && this.coupon.broker)
        return this.coupon.broker.company_name
      else return 'Dunham McCarthy Insurance Services'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    mortgageAmount() {
      if (this.assetsLiabilities.residential_mortgage_amount)
        return this.currency(this.assetsLiabilities.residential_mortgage_amount)
      return null
    },
    lifeInsuranceAmount() {
      if (this.assetsLiabilities.life_insurance_amount)
        return this.currency(this.assetsLiabilities.life_insurance_amount)
      return null
    },
    assetsLiabilities() {
      return this.$store.getters.assets
    },
    people() {
      return this.$store.getters.people
    },
    partner() {
      if (this.people.filter((person) => person.partner).length > 0)
        return this.people.filter((person) => person.partner)[0]
      return false
    },
    primaryDistribution() {
      return this.people.filter(
        (person) =>
          person.distribution_primary || person.distribution_sole_primary
      )
    },
    goal() {
      return (
        'To have the family home pass to ' +
        this.listPeople(this.primaryDistribution, ' or ') +
        ' without the burden of a mortgage, therefore avoiding financial hardship or an insolvent estate.'
      )
    },
    problem() {
      if (this.assetsLiabilities.life_insurance) {
        return (
          'You have a debt of ' +
          this.span(this.mortgageAmount) +
          ' secured on your residential property (' +
          this.span(this.homeAddress(this.personalDetails)) +
          '), although you have a life insurance policy that ' +
          'will pay out ' +
          this.span(this.lifeInsuranceAmount) +
          ' on death, this will be insufficient to repay the full mortgage debt. Without sufficient cover in place to ' +
          'repay your full mortgage ' +
          this.listPeople(this.primaryDistribution, ' or ', true) +
          ' will become responsible for the remaining debt, this may lead to financial ' +
          'difficulty or in the worst case repossession. '
        )
      } else if (!this.assetsLiabilities.life_insurance) {
        return (
          'You have stated that you have a debt of ' +
          this.span(this.mortgageAmount) +
          ' secured on your residential property (' +
          this.span(this.homeAddress(this.personalDetails)) +
          ') but ' +
          'currently you do not have a protection policy in place to repay this debt. Without sufficient cover in place to repay your mortgage ' +
          this.listPeople(this.primaryDistribution, ' or ', true) +
          ' will become responsible for the full debt, this may lead to financial difficulty or in the worst case repossession.'
        )
      }
      return ''
    },
    solution() {
      return (
        'Put a mortgage protection policy in place that will repay your full mortgage debt of ' +
        this.span(this.mortgageAmount) +
        '. This type of cover often costs only a few pounds each week although pricing will vary depending on many factors. Our partners at ' +
        this.broker +
        ' can help you identify the most competitive options in this area.'
      )
    },
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    }
  }
}
</script>

<style></style>
