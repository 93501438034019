<template>
  <article
    :class="{ 'not-selected': !show, selected: show }"
    v-on:click="$emit('selected')"
    class="advice-card-wrapper"
  >
    <div class="advice-card display-flex">
      <div class="advice-title display-flex">
        <h1 v-if="title" v-html="title"></h1>
        <h1 v-else>
          <slot name="title"></slot>
        </h1>
        <div
          v-bind:class="{
            requested: requested,
            declined: requested === false
          }"
          class="advice-img"
        ></div>
      </div>
      <div class="advice-step-wrapper display-flex">
        <div v-if="goal" class="advice-step goal">
          <div class="advice-heading">
            <div class="advice-heading-point"></div>
            The Goal
          </div>
          <div class="advice-content" v-html="goal">
            <slot name="goal"></slot>
          </div>
        </div>
        <div class="advice-step problem">
          <div class="advice-heading">
            <div class="advice-heading-point"></div>
            The Problem
          </div>
          <div class="advice-content" v-html="problem">
            <slot name="problem"></slot>
          </div>
        </div>
        <div class="advice-step solution">
          <div class="advice-heading">
            <div class="advice-heading-point"></div>
            The Solution
          </div>
          <div class="advice-content" v-html="solution">
            <slot name="solution"></slot>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'NewOptionBase',
  props: [
    'image',
    'show',
    'total',
    'goal',
    'problem',
    'solution',
    'requested',
    'title'
  ]
}
</script>
