<template>
  <div :id="id" class="modal is-active modal-advice" :class="{ show: show }">
    <div v-on:click="$emit('close')" class="modal-background"></div>
    <div class="modal-card">
      <section
        class="modal-card-advice-body"
        v-bind:class="{ menu: !splitConfirmButtons }"
      >
        <slot name="menu"></slot>

        <div v-show="showRight" class="advice-body-left">
          <slot name="left"></slot>
        </div>

        <div v-show="showRight" class="advice-body-right">
          <slot name="right"></slot>
        </div>

        <div v-show="!showRight" class="advice-body-right-alternative">
          <slot name="rightAlternative"></slot>
        </div>
      </section>
      <div class="modal-advice-footer-wrapper validated">
        <div
          v-if="splitConfirmButtons"
          class="modal-advice-footer-btn-no-wrapper"
        >
          <button v-on:click="$emit('confirmNo')" class="btn btn-arrow">
            <span class="text">{{ buttonTextNo }}</span>
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div
          v-if="splitConfirmButtons"
          class="modal-advice-footer-btn-yes-wrapper"
        >
          <button v-on:click="$emit('confirmYes')" class="btn btn-arrow">
            <span class="text">{{ buttonTextYes }}</span>
            <i class="fas fa-check"></i>
          </button>
        </div>
        <button
          v-on:click="$emit('confirm')"
          id="js-next"
          class="btn btn-arrow modal-card-footer validated"
        >
          <span class="text">{{ buttonText }}</span>
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModalLargeSplit',
  props: [
    'isValidated',
    'loading',
    'splitConfirmButtons',
    'buttonText',
    'buttonTextYes',
    'buttonTextNo',
    'show',
    'id',
    'showRight'
  ]
}
</script>

<style scoped>
.advice-body-right-alternative {
  height: 100%;
  -ms-flex-preferred-size: 65%;
  flex-basis: 65%;
  background: #ffffff;
  z-index: 2;
  overflow: scroll;
  overflow-x: hidden;
  padding: 20px;
}

@media screen and (max-width: 992px) {
  .modal-advice .modal-card-advice-body .advice-body-right-alternative {
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }
}
</style>
