<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
    @selected="$emit('selected')"
  >
    <template v-slot:title
      >Would you like to discuss <span>'Scottish Legal Rights'</span>?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'

export default {
  name: 'Scotland2',
  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    goal() {
      return null
    },
    problem() {
      return (
        'According to Scottish Law when making your will certain categories of people have ‘legal rights’ ' +
        'over your moveable estate. Your moveable estate is any assets that are not land or buildings i.e. ' +
        'cash, cars, investments etc. In your circumstances one half of these assets will go to ' +
        this.firstWord(this.spouse.full_name) +
        ' automatically and the other half is described as your free estate and will be gifted through this will. '
      )
    },
    solution() {
      var string = ''
      if (this.$store.getters.assets.residential_property)
        string +=
          'Although your residential property is not ' +
          'affected, and for most people this is the most significant asset.  '
      return (string +=
        'Where you would not like your ‘moveable’ estate to be distributed as described above, you should seek ' +
        'further advice on ‘Legal Rights’ and how best to ensure that your moveable assets are passed according' +
        ' to your wishes.')
    },
    spouse() {
      return this.$store.getters.people.filter(
        (person) =>
          (person.partner || person.partner_ex) &&
          (person.relationship === 'wife' ||
            person.relationship === 'husband' ||
            person.relationship === 'ex partner')
      )[0]
    }
  }
}
</script>
