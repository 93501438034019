<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
    :title="title"
    @selected="$emit('selected')"
  >
  </NewOptionBase>
</template>

<script>
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { excludedChildrenHelpers } from '@/common/mixins/excludedChildrenHelpers'

import NewOptionBase from './NewOptionBase'

export default {
  name: 'Children',
  props: ['show', 'total', 'requested'],
  mixins: [willStringHelpers, excludedChildrenHelpers],
  components: {
    NewOptionBase
  },
  computed: {

    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    title() {
      return (
        "Would you like to discuss <span>'" +
        this.listPeopleGroupsCharities(
          this.missingChildren,
          this.missingFutureChildren,
          [],
          ' and ',
          true,
          false,
          true
        ) +
        "'s Exclusion'</span>?"
      )
    },
    goal() {
      return 'To avoid future challenges to your Will and ensure your assets pass to your named beneficiaries.'
    },
    problem() {
      var string =
        'Currently ' +
        this.listPeople(this.missingChildren, ' and ', true) +
        this.isAre +
        ' not named '
      if (this.missingChildren.length === 1) string += 'as a beneficiary'
      else string += ' as beneficiaries'
      string +=
        " in your Will. Under English Law you have <span>'Testamentary Freedom'</span> this means you can leave your estate to whoever you please. However an excluded child has the right to challenge your Will, which can lead to complications and additional expense."
      return string
    },
    solution() {
      var string =
        'If you feel ' +
        this.listPeople(this.missingChildren, ' and ', true) +
        this.isAre
      string +=
        ' likely to challenge your Will there are a few options that may help, the most commonly used method is a <span>Discretionary Trust</span>. This would cost around <span>£200</span> and would not prevent such a challenge, but it would dramatically reduce the likelihood of a successful challenge.'
      return string
    },
  }
}
</script>
