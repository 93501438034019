<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
    @selected="$emit('selected')"
  >
    <template v-slot:title
      >Would you like to discuss <span>'Funeral Plan'</span>?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'

export default {
  name: 'FuneralPlan',
  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    assetsLiabilities() {
      return this.$store.getters.assets
    },
    overAmount() {
      var amount = 8000
      if (
        this.assetsLiabilities.savings_bank &&
        this.assetsLiabilities.savings_bank_amount &&
        (Number(this.assetsLiabilities.savings_bank_amount.replace(',', '')) >=
          amount ||
          this.assetsLiabilities.savings_bank_amount >= amount)
      ) {
        return true
      }
      return false
    },
    goal() {
      if (this.overAmount) {
        return 'To provide peace of mind for you and your family by ensuring that your funeral costs are paid.'
      } else {
        return 'To provide peace of mind by ensuring that funds are quickly available to cover the costs of your funeral.'
      }
    },
    problem() {
      if (this.overAmount) {
        return 'The average cost of a funeral now stands at £4,271, having more than doubled since 2004. If costs continue to rise at a similar rate the costs will be over £13,000 in twenty years from now. The problem is that whilst funeral costs are increasing at over 4% - 8% each year, our savings are not. At best this will lead to a lower inheritance for your family at worst a shortfall in costs.'
      } else {
        return 'The average cost of a funeral now stands at £4,271, having more than doubled since 2004. Once you include the extras such as flowers, wreaths, catering and venue hire (around an additional £1,800), it brings the bill up to a staggering £6,000. Because money is often tied up in the family home, families often experience a shortfall when it comes to paying. This can be an added stress at an already stressful time.'
      }
    },
    solution() {
      if (this.overAmount) {
        return 'Using a funeral plan you could pay foy for your funeral at todays prices, therefore fixing the cost. This is an easy way to organise your funeral arrangemnets in advance, specify your wishes and take care of the costs, all for as little as £3400.'
      } else {
        return 'You could pay a fixed amount each month for a funeral plan, when your time comes the plan provider will cover the full costs of your funeral. This is an easy way to organise your funeral arrangements in advance, specify your wishes and take care of the costs, all for as little as £20 per month.'
      }
    }
  }
}
</script>

<style></style>
