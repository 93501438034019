<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
    @selected="$emit('selected')"
  >
    <template v-slot:title
      >Would you like to discuss your<span>'Vulnerable beneficiaries'</span
      >?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'

export default {
  name: 'Disabled',
  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    goal() {
      return 'To protect and ring-fence the inheritance of any beneficiaries who are vulnerable or disabled.'
    },
    problem() {
      return (
        'You have indicated that a beneficiary is vulnerable and/or disabled, this can lead to complications. ' +
        'Firstly inheriting a sum of money can reduce or even eliminate their eligibility to means-tested benefits. ' +
        'Additionally if your beneficiary is vulnerable or lacks capacity, you need to think about the risk of financial ' +
        'abuse and whether they may need help managing the money.'
      )
    },
    solution() {
      return (
        'In this situation a trust can be created to prevent any inheritance from interfering with the persons ' +
        'benefits, also where necessary trustees can be appointment to manage the money on your beneficiaries behalf. We ' +
        'strongly recommend that you discuss this with a specialist. Advice is free of charge, the drafting of a specialist ' +
        'disabled persons trust will cost approximately £200 - £350.'
      )
    }
  }
}
</script>
