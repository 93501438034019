<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    @selected="$emit('selected')"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
  >
    <template v-slot:title
      >Would you like to discuss <span>'Inheritance Tax'</span>?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'InheritanceTax',
  mixins: [willStringHelpers],
  props: ['show', 'total', 'totalEstate', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    people() {
      return this.$store.getters.people
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    partner() {
      return this.$store.getters.partner
    },
    residuaryBeneficiaries() {
      return this.people.filter((p) => p.distribution_primary)
    },
    goal() {
      if (this.personalDetails.marital_status === 'Married') {
        // everyone who is married
        return (
          'To maximise ' +
          this.listPeople(this.residuaryBeneficiaries) +
          ' inheritance by minimising the amount of Inheritance Tax (IHT) payable.'
        )
      } else if (
        this.personalDetails.marital_status === 'Single' ||
        this.personalDetails.marital_status === 'Separated'
      ) {
        // everyone without a partner
        return (
          'To maximise the funds that pass to ' +
          this.listPeople(this.residuaryBeneficiaries) +
          ' by minimising the amount of Inheritance Tax (IHT) payable.'
        )
      } else {
        // everyone else with a partner but not married
        return (
          'To maximise the funds that pass to your partner ' +
          this.span(this.partner.full_name) +
          ' and other beneficiaries by minimising the amount of Inheritance Tax (IHT) payable.'
        )
      }
    },
    problem() {
      if (this.personalDetails.marital_status === 'Married') {
        return (
          'Calculating your exact inheritance tax liability is difficult as assets will fluctuate in value and ' +
          this.firstWord(this.partner.full_name) +
          ' estate / assets will' +
          ' have a significant effect on your situation, however given that your net estate is valued at over £325,000 Inheritance Tax be applicable. As IHT is payable at 40% this could mean tens of thousands of pounds passing to HMRC instead of ' +
          this.listPeople(this.residuaryBeneficiaries, ' and ', true) +
          '.'
        )
      } else if (
        this.personalDetails.marital_status === 'Single' ||
        this.personalDetails.marital_status === 'Separated'
      ) {
        return (
          'Calculating your exact inheritance tax liability is difficult as assets will fluctuate in value and tax law is complex, however given that your net estate is valued at over ' +
          ' £325,000 Inheritance Tax may be applicable. As IHT is payable at 40%, ' +
          'thousands of pounds could pass to HMRC instead of ' +
          this.listPeople(this.residuaryBeneficiaries, ' and ', true) +
          '.'
        )
      } else {
        return (
          'Calculating your exact inheritance tax liability is difficult as assets will fluctuate in value and tax law is complex, however given that your net estate is valued ' +
          ' at over £325,000 Inheritance Tax may be applicable. As IHT is payable at 40%' +
          ' and no tax relief is available to un-married couples, thousands of pounds could pass to HMRC instead of ' +
          this.firstWord(this.partner.full_name) +
          '. '
        )
      }
    },
    solution() {
      if (this.personalDetails.marital_status === 'Married') {
        return (
          'For £500 inc VAT our partners at Dunham McCarthy can undertake a detailed IHT review. There are many simple (and some complex) ways of reducing or even eliminating Inheritance Tax. With the help of a professional estate planner and/or financial advisor ' +
          ' you can maximise the amount of money that is retained by ' +
          this.listPeople(this.residuaryBeneficiaries, ' and ', true) +
          '.'
        )
      } else if (
        this.personalDetails.marital_status === 'Single' ||
        this.personalDetails.marital_status === 'Separated'
      ) {
        return (
          'There are many simple (and some complex) ways of reducing or even eliminating Inheritance Tax. With the help of a professional estate planner and/or financial advisor ' +
          ' you can maximise the amount of money that is retained by ' +
          this.listPeople(this.residuaryBeneficiaries, ' and ', true) +
          '.'
        )
      } else {
        return (
          'There are many simple (and some complex) ways of reducing or even eliminating Inheritance Tax. With the help of a professional estate planner and/or ' +
          'financial advisor you can maximise the amount of money that is retained by ' +
          this.firstWord(this.partner.full_name) +
          '.'
        )
      }
    }
  },
  methods: {}
}
</script>
