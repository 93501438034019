<template>
  <aside
    class="left-sidebar"
    v-bind:class="{ 'expand-logo': $store.getters.sidebarHover }"
    v-on:mouseenter="hoverSidebar"
    v-on:mouseleave="hoverSidebar"
  >
    <div
      v-if="progressPercentage"
      class="percentage-completed-wrapper is-hidden-mobile"
    >
      <div class="percentage-completed-inner display-flex">
        <div class="percentage-completed">{{ progressPercentage }}%</div>
        <div class="percentage-completed-text">Completed</div>
      </div>
    </div>

    <perfect-scrollbar :options="{ suppressScrollX: true }">
      <nav class="sidebar-nav">
        <ul id="sidebarnav" v-on:click="mobileCollapse">
          <li></li>
          <slot name="menu"> </slot>
        </ul>
      </nav>
    </perfect-scrollbar>
  </aside>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  name: 'TheSidebar',
  components: {
    PerfectScrollbar
  },
  props: ['progressPercentage'],
  data() {
    return {
      headerHover: false
    }
  },
  methods: {
    hoverSidebar: function () {
      this.$store.commit('sidebarHover')
    },
    mobileCollapse() {
      this.$store.commit('sidebarMobileCollapse')
    }
  }
}
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
<style scoped>
.ps {
  height: 100%;
}
</style>
