<template>
  <article
    :class="{ 'not-selected': !show, selected: show }"
    v-on:click="$emit('selected')"
    class="advice-card-wrapper"
  >
    <div class="advice-card">
      <div class="advice-title">
        <h1 class="mb30">Telephone Appointment</h1>
        <div class="question-section-intro mb30">
          As you have requested advice the next step is to book an appointment
          for us to contact you. Please select from the avaliable times below:
        </div>
      </div>
      <div
        style="margin-bottom: 80px"
        v-if="appointments && sortedAppointments"
      >
        <div class="mb-30" style="display: flex; flex-direction: column">
          <div
            style
            v-for="(key, index) in Object.keys(sortedAppointments)"
            :key="index"
          >
            <h3 class="appointment-title">{{ key }}</h3>

            <div
              v-for="(appointment, indexApp) in sortedAppointments[key]"
              :key="indexApp"
              class="appointment"
              :class="{
                selected: appointment.id == selectedAppointment
              }"
              @click="$emit('setAppointment', appointment.id)"
            >
              <div>{{ formatTime(appointment.date_time) }}</div>
              <div style="text-align: right">
                <span
                  v-if="
                    appointment.id === selectedAppointment &&
                    originalBookedAppointment !== appointment.id
                  "
                  >Reserve</span
                >
                <span v-if="appointment.id === originalBookedAppointment"
                  >Booked</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="button-wrapper">
          <!--
          <button
            class="btn btn-small btn--secondary"
            :disabled="!appointments.has_previous"
            @click="pageNumber--"
          >Previous Page</button>
          <button
            class="btn btn-small btn--secondary"
            :disabled="!appointments.has_next"
            @click="pageNumber++"
          >Next Page</button>
          -->
        </div>
        <div class="button-wrapper mt30">
          <!--
          <a href="#"  class="collapseCoupon" @click="$emit('showOverview')">Back</a><br>
          -->
          <a href="#" class="collapseCoupon" @click="$emit('cancelAppointment')"
            >Continue Without Appointment</a
          >
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { TextFormat } from '@/common/mixins/TextFormat'
import { http } from '@/services'

export default {
  name: 'Appointment',
  props: ['show', 'total', 'requested', 'selectedAppointment'],
  mixins: [willStringHelpers, TextFormat],
  components: {},
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) this.appointmentsFetch()
      }
    },
    pageNumber(value) {
      this.appointmentsFetch()
    }
  },
  data() {
    return {
      appointments: null,
      pageNumber: 1
    }
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    sortedAppointments() {
      var sorted = {}

      if (this.appointments && this.appointments.data) {
        for (var i = 0; i < this.appointments.data.length; i++) {
          var keys = Object.keys(sorted)
          var currentDate = new Date(
            this.appointments.data[i].date_time
          ).toLocaleDateString()

          if (!keys.includes(currentDate)) {
            sorted[currentDate] = []
            for (var n = 0; n < this.appointments.data.length; n++) {
              if (
                currentDate ===
                new Date(
                  this.appointments.data[n].date_time
                ).toLocaleDateString()
              ) {
                sorted[currentDate].push(this.appointments.data[n])
              }
            }
          }
        }
      }

      return sorted
    },
    originalBookedAppointment() {
      return this.$store.getters.adviceNew.appointment
    }
  },
  methods: {
    appointmentsFetch() {
      var params = {
        params: {
          page_number: this.pageNumber
        }
      }
      http
        .get('/wills/api/advice_appointments', params)
        .then((response) => {
          this.appointments = response.data
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    }
  }
}
</script>

<style scoped>
.appointment-request {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  flex-basis: 25%;
  background-color: #345a7a;
  height: 65%;
  color: white;
  text-align: center;
  border-radius: 2px;
  position: relative;
  max-width: 135px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
h2 {
  font-size: 3vh;
}

.appointment-title {
  background-color: #e56781;
  border-radius: 5px;
  color: white;
  padding: 10px;
}
.appointment {
  background-color: #faf9fe;
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0;
  cursor: pointer;
}
.appointment.selected {
  -webkit-animation: selected 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
  animation: selected 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  z-index: 2;
  -webkit-box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.1);
  background-color: beige;
}
</style>
