<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
    @selected="$emit('selected')"
  >
    <template v-slot:title
      >Would you like to discuss <span>'Right to Occupy'</span>?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'

export default {
  name: 'RightToOccupy1',
  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    goal() {
      return null
    },
    problem() {
      return (
        'Leaving your property to your partner could mean that at some point your children may never benefit ' +
        "from it, they could meet someone new, sell the house or go into care meaning your largest asset doesn't " +
        'end up where you wish it to go.'
      )
    },
    solution() {
      return (
        'By adding a right of occupation trust into your will you will ensure that your spouse/partner has ' +
        'somewhere to live for the rest of their life but that this can never be gifted to a new spouse or be ' +
        'used for their care fees, therefore, protecting your home for your children. The fee to do this is £200.'
      )
    }
  }
}
</script>
