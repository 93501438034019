<template>
  <NewOptionBase
    :show="show"
    :style="setStyle"
    :goal="goal"
    :problem="problem"
    :solution="solution"
    @selected="$emit('selected')"
    :requested="requested"
    :image="'https://mylastwill.s3.amazonaws.com/static/img/advice-ppt.png'"
  >
    <template v-slot:title
      >Would you like to discuss <span>'Protecting Your Home'</span>?</template
    >
  </NewOptionBase>
</template>

<script>
import NewOptionBase from './NewOptionBase'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'HomeProtection',
  mixins: [willStringHelpers],
  props: ['show', 'total', 'requested'],
  components: {
    NewOptionBase
  },
  computed: {
    setStyle() {
      if (this.total) return '--data-x:' + this.total
      return null
    },
    children() {
      return this.$store.getters.people.filter(
        (person) =>
          person.child === true &&
          (person.distribution_primary || person.distribution_backup)
      )
    },
    partner() {
      return this.$store.getters.people.filter((p) => p.partner === true)[0]
    },
    goal() {
      return (
        'To have the family home passed down for the future benefit of ' +
        this.listPeople(this.children) +
        '.'
      )
    },
    problem() {
      return (
        'You are leaving your entire estate directly to' +
        this.relationYour(this.partner.relationship) +
        ' ' +
        this.span(this.partner.full_name) +
        ', this is quite normal in your situation.  However after you have passed away all of the assets will be with ' +
        this.firstWord(this.partner.full_name) +
        ', if ' +
        this.firstWord(this.partner.full_name, true) +
        ' situation changes (if ' +
        this.heShe(this.partner.sex) +
        ' meets a new partner, changes ' +
        this.hisHer(this.partner.gender) +
        ' Will, has financial difficulties and goes bankrupt or goes into a care home) the family home may not ultimately pass to ' +
        this.listPeople(this.children) +
        '.'
      )
    },
    solution() {
      return (
        'A trust could be created to protect the family home for ' +
        this.listPeople(this.children) +
        '. There are different types of trusts available, ' +
        'most are effective against future care fees, divorce and bankruptcy. A typical trust would cost £349 to setup.'
      )
    }
  },
  methods: {}
}
</script>
