<template>
  <div>
    <div
      id="main-wrapper"
      v-bind:class="{
        'is-active': hamburgerStatus,
        'show-sidebar': $store.getters.sidebarMobile
      }"
      v-bind:data-sidebartype="$store.getters.sidebar"
    >
      <TheTopbar>
        <template v-slot:desktopMenu>

          <li
            v-on:click="switchAccountHelpMenu"
            id="nav-item-2"
            class="nav-item dropdown"
          >
            <a
              class="nav-link dropdown-toggle waves-effect waves-dark"
              href="javascript:void(0)"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="mdi mdi-help-circle"></i>
              <div class="nav-link-text">Help</div>
            </a>
            <div
              v-bind:class="{ show: accountHelpMenu }"
              class="dropdown-menu dropdown-menu-right animated flipInY"
            >
              <span class="with-arrow">
                <span class="bg-white-color"></span>
              </span>
              <ul>
                <li class="hover-ver2"></li>
                <div>
                  <li class="hover-ver2">
                    <a :href="help" target="_blank"
                      ><i class="ti-book"></i>
                      Help Centre
                    </a>
                  </li>
                  <li class="hover-ver2">
                    <a v-on:click="showAdviceModalNonCheckout" href="#"
                      ><i class="ti-help"></i>
                      Advice Centre
                    </a>
                  </li>
                  <li class="hover-ver2">
                    <a
                      v-on:click="showChat"
                      href="javascript:void(0)"
                      class="live-chat-help"
                      ><i class="ti-comments"></i>
                      Live Chat
                    </a>
                  </li>
                  <li class="hover-ver2">
                    <a tabindex="-1" :href="contactForm" target="_blank"
                      ><i class="ti-headphone-alt"></i>
                      Contact
                    </a>
                  </li>
                </div>
              </ul>
              <img
                src="https://mylastwill.s3.amazonaws.com/static/img/help-img.png"
                alt="help"
              />
            </div>
          </li>

          <li class="nav-item dropdown">
            <router-link
              to="/people"
              tag="a"
              class="nav-link waves-effect waves-dark"
              aria-haspopup="true"
              aria-expanded="false"
              v-on:click.native="closeTopMenus"
            >
              <i class="mdi mdi-account-multiple">
                <div class="number" v-show="$store.getters.people.length">
                  {{ $store.getters.people.length }}
                </div>
              </i>
              <div class="nav-link-text">People</div>
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link
              to="/documents"
              tag="a"
              class="nav-link waves-effect waves-dark"
              aria-haspopup="true"
              aria-expanded="false"
              v-on:click.native="closeTopMenus"
            >
              <i class="mdi mdi-file-document">
                <div
                  class="number"
                  v-show="
                    $store.getters.documents.filter((doc) => doc.checked).length
                  "
                >
                  {{
                    $store.getters.documents.filter((doc) => doc.checked).length
                  }}
                </div>
              </i>
              <div class="nav-link-text">Documents</div>
            </router-link>
          </li>

          <li
            v-on:click="switchAccountMobileMenu"
            id="nav-item-5"
            class="nav-item dropdown"
          >
            <a
              class="nav-link dropdown-toggle waves-effect waves-dark"
              href="javascript:void(0)"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="mdi mdi-account-circle"></i>
              <div class="nav-link-text">Account</div>
            </a>
            <div
              v-bind:class="{ show: accountMobileMenu }"
              class="dropdown-menu dropdown-menu-right animated flipInY"
            >
              <span class="with-arrow">
                <span class="bg-primary-color"></span>
              </span>
              <div class="dropdown-header bg-primary-color">
                <div>
                  <h4></h4>
                  <p v-if="user">{{ user.email }}</p>
                </div>
              </div>
              <ul>
                <router-link
                  v-on:click.native="$store.commit('sidebarMobileToggle')"
                  to="/account"
                  tag="li"
                  exact-active-class="selected"
                  class="hover-ver2"
                >
                  <a href="javascript:void(0)"
                    ><i class="ti-panel"></i>Account</a
                  >
                </router-link>
                <router-link
                  v-on:click.native="$store.commit('sidebarMobileToggle')"
                  to="/documents"
                  tag="li"
                  exact-active-class="selected"
                  class="hover-ver2"
                >
                  <a href="javascript:void(0)"
                    ><i class="ti-files"></i>Documents</a
                  >
                </router-link>
                <li style="list-style: none; display: inline">
                  <div class="dropdown-divider"></div>
                </li>
                <li class="sub-title">Need Help?</li>
                <li class="hover-ver2 live-chat-help">
                  <a v-on:click="showChat" href="javascript:void(0)"
                    ><i class="ti-comments"></i>Live Chat</a
                  >
                </li>

                <li class="hover-ver2">
                  <a href="tel:01785338292"
                    ><i class="ti-headphone-alt"></i>01785 338292</a
                  >
                </li>

                <li style="list-style: none; display: inline">
                  <div class="dropdown-divider"></div>
                </li>
                <li class="hover-ver2">
                  <a @click="logout" href="#" tabindex="0">
                    <i class="mdi mdi-directions"></i>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li
            v-on:click="switchAccountDesktopMenu"
            id="nav-item-6"
            class="nav-item nav-item-6 dropdown"
          >
            <a
              class="nav-link dropdown-toggle waves-effect waves-dark"
              href="javascript:void(0)"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="mdi mdi-account"></i>
              <span class="fa fa-chevron-down"></span>
            </a>
            <div
              v-bind:class="{ show: accountDesktopMenu }"
              class="dropdown-menu dropdown-menu-right animated flipInY"
            >
              <span class="with-arrow">
                <span class="bg-primary-color"></span>
              </span>
              <div class="dropdown-header bg-primary-color">
                <div>
                  <h4></h4>
                  <p v-if="user">{{ user.email }}</p>
                  <!--<small>Last Login {{ formatDateAndTime($store.getters.user.last_login) }}</small>-->
                </div>
              </div>
              <ul>
                <router-link
                  to="/account"
                  tag="li"
                  exact-active-class="selected"
                  class="hover-ver2"
                >
                  <a href="javascript:void(0)"
                    ><i class="ti-panel"></i>Account</a
                  >
                </router-link>
                <router-link
                  to="/documents"
                  tag="li"
                  exact-active-class="selected"
                  class="hover-ver2"
                >
                  <a href="javascript:void(0)"
                    ><i class="ti-files"></i>Documents</a
                  >
                </router-link>
                <li style="list-style: none; display: inline">
                  <div class="dropdown-divider"></div>
                </li>
                <li class="sub-title">Need Help?</li>
                <li class="hover-ver2 live-chat-help">
                  <a v-on:click="showChat" href="javascript:void(0)"
                    ><i class="ti-comments"></i>Live Chat</a
                  >
                </li>

                <li class="hover-ver2">
                  <a href="tel:01785338292"
                    ><i class="ti-headphone-alt"></i>01785 338292</a
                  >
                </li>

                <li style="list-style: none; display: inline">
                  <div class="dropdown-divider"></div>
                </li>
                <li class="hover-ver2">
                  <a @click="logout" href="#" tabindex="0">
                    <i class="mdi mdi-directions"></i>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </TheTopbar>
      <TheSidebar
        v-bind:progressPercentage="
          $store.getters.progressSummary.percentageComplete
        "
      >
        <template v-slot:menu>
          <li>
            <ul v-bind:class="{ shrunk: false }">
              <router-link
                to="/home"
                tag="li"
                exact-active-class="selected"
                class="sidebar-item"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                >
                  <i class="fab fa-elementor"></i>
                  <span class="hide-menu">Overview</span>
                </a>
              </router-link>
            </ul>
            <ul
              v-bind:class="{
                shrunk: shrinkMenuMainComputed || shrinkMenuComputed
              }"
            >
              <router-link
                to="/profile"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressProfile.profileCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                >
                  <i class="fas fa-id-card"></i>
                  <span class="hide-menu">Profile</span>
                </a>
              </router-link>
              <router-link
                v-if="$store.getters.progressChildren.childrenRequired"
                to="/children"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressChildren.childrenCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-child"></i>
                  <span class="hide-menu">Children</span>
                </a>
              </router-link>
              <router-link
                to="/executors"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressExecutors.executorsCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-user-tie"></i>
                  <span class="hide-menu">Executors</span>
                </a>
              </router-link>
              <router-link
                v-if="$store.getters.progressTrustees.trusteesRequired"
                to="/trustees"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressTrustees.trusteesCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-users"></i>
                  <span class="hide-menu">Trustees</span>
                </a>
              </router-link>

              <router-link
                v-if="$store.getters.progressGuardians.guardiansRequired"
                to="/guardians"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressGuardians.guardiansCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-baby"></i>
                  <span class="hide-menu">Guardians</span>
                </a>
              </router-link>
              <router-link
                to="/distribution"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed:
                    $store.getters.progressDistribution.distributionCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-chart-pie"></i>
                  <span class="hide-menu">Distribution</span>
                </a>
              </router-link>
              <router-link
                to="/gifts"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressGifts.giftsCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-gifts"></i>
                  <span class="hide-menu">Gifts</span>
                </a>
              </router-link>

              <router-link
                to="/funeral"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressFuneral.funeralCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-place-of-worship"></i>
                  <span class="hide-menu">Funeral</span>
                </a>
              </router-link>
              <router-link
                v-if="$store.getters.progressPets.petsRequired"
                to="/pets"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressPets.petsCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-paw"></i>
                  <span class="hide-menu">Pets</span>
                </a>
              </router-link>
              <router-link
                to="/assets"
                tag="li"
                active-class="selected"
                class="sidebar-item"
                v-bind:class="{
                  completed: $store.getters.progressAssets.assetsCompleted
                }"
              >
                <a
                  class="sidebar-link has-arrownorotate waves-effect waves-dark"
                  aria-expanded="false"
                >
                  <i class="fas fa-home"></i>
                  <span class="hide-menu">Assets</span>
                </a>
              </router-link>
            </ul>
          </li>
          <li
            v-bind:class="{ selected: shrinkMenuComputed }"
            class="sidebar-item"
            id="js-side-menu-checkout"
          >
            <router-link
              v-if="!shrinkMenuMainComputed"
              :to="submitTo"
              tag="li"
              active-class="selected"
              class="sidebar-item"
            >
              <a
                class="sidebar-link waves-effect waves-dark"
                aria-expanded="false"
              >
                <i class="fas fa-file-import"></i>
                <span class="hide-menu">{{ submitText }}</span>
              </a>
            </router-link>

            <div class="wrapper">
              <ul class="StepProgress">
                <router-link
                  event=""
                  to="/create_will"
                  tag="li"
                  exact-active-class="current"
                  class="StepProgress-item"
                  v-bind:class="{
                    'is-done': checkoutProgress.createWill
                  }"
                >
                  <strong>Create</strong>
                </router-link>

                <router-link
                  v-if="!$store.getters.checkoutBasket.active_subscription"
                  event=""
                  to="/storage"
                  tag="li"
                  exact-active-class="current"
                  class="StepProgress-item"
                  v-bind:class="{
                    'is-done': checkoutProgress.package,
                    'reset-numbers': !$store.getters.checkoutBasket
                      .active_subscription
                  }"
                >
                  <strong>Updates</strong>
                  <div class="step-info">
                    Choose your services and packages.
                  </div>
                </router-link>

                <router-link
                  v-if="
                    !$store.getters.checkoutBasket.active_subscription &&
                    !$store.getters.checkoutBasket.package
                  "
                  event=""
                  to="/printing"
                  tag="li"
                  exact-active-class="current"
                  class="StepProgress-item"
                  v-bind:class="{
                    'is-done': checkoutProgress.printing,
                    'reset-numbers': !$store.getters.checkoutBasket
                      .active_subscription
                  }"
                >
                  <strong>Printing</strong>
                  <div class="step-info">
                    Choose your services and packages.
                  </div>
                </router-link>

                <router-link
                  event=""
                  to="/checking"
                  tag="li"
                  exact-active-class="current"
                  class="StepProgress-item"
                  v-bind:class="{
                    'is-done': checkoutProgress.checking
                  }"
                  ><strong>Checking</strong>
                  <div class="step-info">Submit your Will for checking.</div>
                </router-link>

                <router-link
                  event=""
                  to="/pay"
                  tag="li"
                  active-class="current"
                  class="StepProgress-item"
                  v-bind:class="{
                    'is-done': checkoutProgress.pay
                  }"
                  ><strong>Pay</strong>
                  <div class="step-info">Confirm payment amount.</div>
                </router-link>
                <router-link
                  event=""
                  to="/advice"
                  tag="li"
                  exact-active-class="current"
                  class="StepProgress-item"
                  v-bind:class="{
                    'is-done': checkoutProgress.advice
                  }"
                  ><strong>Follow Up</strong>
                  <div class="step-info">
                    Suggestions based upon your situation.
                  </div>
                </router-link>

                <router-link
                  event=""
                  to="/share"
                  tag="li"
                  exact-active-class="current"
                  class="StepProgress-item"
                  v-bind:class="{
                    'is-done': checkoutProgress.share
                  }"
                  ><strong>Share</strong>
                  <div class="step-info">Share details of appointments.</div>
                </router-link>

                <router-link
                  event=""
                  to="/draft_will"
                  tag="li"
                  exact-active-class="current"
                  class="StepProgress-item"
                  v-bind:class="{
                    'is-done': checkoutProgress.draft
                  }"
                  ><strong>View Draft</strong>
                  <div class="step-info">
                    Suggestions based upon your situation.
                  </div>
                </router-link>
              </ul>
            </div>
          </li>
        </template>
      </TheSidebar>
      <div class="page-wrapper">
        <router-view
          @showAdviceModalCheckout="showAdviceModalCheckout"
          @closeAdviceModal="closeAdviceModal"
        />
      </div>
    </div>

    <AdviceModal
      v-show="show.adviceModal"
      :checkout="adviceModalCheckout"
      :shown="show.adviceModal"
      @close="closeAdviceModal"
    ></AdviceModal>
    <offline @detected-condition="handleConnectivityChange"> </offline>
  </div>
</template>

<script>
import TheSidebar from '@/common/layout/TheSidebar'
import TheTopbar from '@/common/layout/TheTopbar'
import AdviceModal from '@/views/pages/CheckoutView/AdvicePage/AdviceModal'
import { TextFormat } from '@/common/mixins/TextFormat'
import offline from 'v-offline'

export default {
  name: 'MainPage',
  mixins: [TextFormat],
  components: {
    TheSidebar,
    TheTopbar,
    AdviceModal,
    offline
  },
  watch: {
    tempAccount(value) {
      if (value) {
        this.$notification.error(`Temporary user account`, {
          showLeftIcn: true,
          showCloseIcn: true,
          infiniteTimer: false,
          position: 'topCenter'
        })
      }
    }
  },
  data() {
    return {
      online: true,
      hamburgerStatus: false,
      menuShrunk: false,
      hamburgerCollapsed: false,
      headerHover: false,
      accountDesktopMenu: false,
      accountMobileMenu: false,
      accountHelpMenu: false,
      show: {
        adviceModal: false
      },
      checkoutRoutes: [
        'CreateWillPage',
        'ShareView',
        'StoragePage',
        'PrintingPage',
        'CheckingPage',
        'PayPage',
        'AdvicePage',
        'NewCustomer',
        'DirectDebitSetupPage'
      ],
      adviceModalCheckout: false
    }
  },
  computed: {
    checkoutProgress() {
      return this.$store.getters.checkoutProgress
    },
    progress() {
      return this.$store.getters.progress
    },
    shrinkMenuComputed() {
      if (this.checkoutRoutes.includes(this.$route.name)) {
        return true
      }
      return false
    },
    shrinkMenuMainComputed() {
      if (this.$route.name === 'DraftWillPage') {
        return true
      }
      return false
    },
    user() {
      return this.$store.getters.user
    },
    tempAccount() {
      return this.user && this.user.temp_account && this.user.change_password
    },
    submitText() {
      if (this.tempAccount) return 'Handover'
      return this.$store.getters.willUpdate ? 'Submit Updates' : 'Create Will'
    },
    submitTo() {
      if (this.tempAccount) return '/handover'
      return '/create_will'
    },
    help() {
      return process.env.NODE_ENV === 'production'
        ? 'https://www.mylastwill.co.uk/contact'
        : 'http://127.0.0.1:8000/contact'
    },
    contactForm() {
      return process.env.NODE_ENV === 'production'
        ? 'https://www.mylastwill.co.uk/contact'
        : 'http://127.0.0.1:8000/contact_form'
    }
  },
  created() {
    this.$store.dispatch('inspectToken')
  },
  mounted() {
    window.addEventListener('resize', this.changeSideBarType)
  },
  methods: {
    handleConnectivityChange(status) {
      if (status === false) {
        this.online = false
        this.$notification.error(
          'INTERNET CONNECTION ERROR, please check your connection, data may not be saved',
          { infiniteTimer: true }
        )
      } else if (this.online === false) {
        this.online = true
        this.$notification.removeAll()
        this.$notification.success('Internet Connected')
      }
    },
    logout() {
      this.$notification.removeAll()
      this.$store.dispatch('logout')
      this.$notification.success('You have been logged out')
    },
    sidebarHamburger: function () {
      this.$store.getters.sidebar === 'full'
        ? (this.hamburgerStatus = false)
        : (this.hamburgerStatus = true)
    },
    shrinkMenu() {
      this.menuShrunk = true
    },
    changeSideBarType() {
      var width = document.documentElement.clientWidth
      if (width < 1280) {
        this.$store.commit('sidebar', 'mini-sidebar')
      } else {
        this.$store.commit('sidebar', 'full')
      }
    },
    switchAccountMobileMenu() {
      this.accountMobileMenu = !this.accountMobileMenu
    },
    switchAccountDesktopMenu() {
      this.accountDesktopMenu = !this.accountDesktopMenu
      this.accountHelpMenu = false
    },
    switchAccountHelpMenu() {
      this.accountHelpMenu = !this.accountHelpMenu
      this.accountDesktopMenu = false
    },
    closeTopMenus() {
      this.$store.commit('sidebarMobileToggle')
      this.accountDesktopMenu = false
      this.accountHelpMenu = false
    },
    showAdviceModalCheckout() {
      this.adviceModalCheckout = true
      this.show.adviceModal = true
    },
    showAdviceModalNonCheckout() {
      this.adviceModalCheckout = false
      this.show.adviceModal = true
    },
    closeAdviceModal() {
      this.show.adviceModal = false
    },
    showChat() {
      document.getElementById('chat-head').click()
    }
  }
}
</script>

<style scoped>
small {
  font-size: 12px;
}
</style>
