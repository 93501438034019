import { render, staticRenderFns } from "./TheSidebar.vue?vue&type=template&id=66654a5c&scoped=true"
import script from "./TheSidebar.vue?vue&type=script&lang=js"
export * from "./TheSidebar.vue?vue&type=script&lang=js"
import style0 from "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./TheSidebar.vue?vue&type=style&index=1&id=66654a5c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66654a5c",
  null
  
)

export default component.exports