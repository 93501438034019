<template>
  <header class="topbar">
    <div id="tutorial-background" class="tutorial-background"></div>

    <nav id="navbar" class="navbar top-navbar navbar-expand">
      <div
        class="navbar-header"
        v-bind:class="{ 'expand-logo': $store.getters.sidebarHover }"
      >
        <a class="navbar-brand" :href="logoLink" tabindex="-1">
          <b class="logo-icon">
            <img
              src="https://mylastwill.s3.amazonaws.com/static/img/logo-light-icon.png"
              alt="homepage"
              class="light-logo"
            />
          </b>
          <span class="logo-text"> <span>my</span>lastwill </span>
        </a>

        <a
          v-on:click="$store.commit('sidebarMobileToggle')"
          class="topbartoggler display-block d-md-none waves-effect waves-light"
          href="javascript:void(0)"
          tabindex="-1"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <div
            v-bind:class="{
              'is-active': $store.getters.sidebarMobile
            }"
            id="menu-meatball"
            class="menu-meatball"
            tabindex="5"
          >
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
          </div>
        </a>
      </div>

      <div
        v-bind:class="{ show: $store.getters.sidebarMobile }"
        class="navbar-collapse collapse"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav f-left mr-auto">
          <li class="nav-item display-none d-md-block">
            <a
              data-sidebartype="mini-sidebar"
              class="nav-link sidebartoggler waves-effect waves-light"
              href="javascript:void(0)"
              v-on:click="sidebarToggle"
            >
              <button
                v-bind:class="{ 'is-active': hamburgerStatus }"
                class="hamburger hamburger--collapse"
                type="button"
              >
                <span class="hamburger-box">
                  <span class="hamburger-inner"></span>
                </span>
              </button>
            </a>
          </li>
        </ul>

        <ul class="navbar-nav f-right">
          <slot name="desktopMenu"></slot>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  computed: {
    hamburgerStatus: function () {
      if (this.$store.getters.sidebar === 'full') {
        return true
      } else {
        return false
      }
    },
    logoLink() {
      return process.env.NODE_ENV === 'production'
        ? 'https://www.mylastwill.co.uk/'
        : 'http://127.0.0.1:8000/'
    }
  },
  methods: {
    sidebarToggle: function () {
      this.$store.commit('sidebarToggle')
    }
  }
}
</script>

<style scoped>
.topbar {
  z-index: 40;
}
</style>
